import styled from 'styled-components';

import { query } from '@shared/style-helpers';

export const TableWrapper = styled.div`
  width: 1215px;
  max-height: ${({ expanded }) => (expanded ? '2514px' : '544px')};
  transition: max-height 1s ease-in-out;
  overflow-y: hidden;

  @media ${query.maxMobile} {
    width: 1000px;
  }
`;

export const CellContentWrapper = styled.div`
  vertical-align: middle;
  display: inline-flex;
  align-items: center;

  cursor: ${({ onClick }) => onClick && 'pointer'};
`;
