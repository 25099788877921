import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@core';
import { ImageCore, Table, Text } from '@components';
import { ArrowIcon } from '@components/arrow-link/arrow-icon';

import ascendingSortIcon from '@images/adoption-index/ascending-sort.svg';
import descendingSortIcon from '@images/adoption-index/descending-sort.svg';
import defaultSortIcon from '@images/adoption-index/no-sort.svg';
import { sortDataByKey } from '@shared/helpers';
import { GA4_EVENTS } from '@shared/constants/google';

import { CellContentWrapper, TableWrapper } from './wrapper-components';

const sortProperties = {
  popularity: 'popularity',
  victims: 'victims',
  losses: 'losses',
  score: 'score',
};

const CrimeRatingTable = ({ labels, crimeRatingData }) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);

  const [expanded, setExpanded] = useState(false);
  const [sortToggle, setSortToggle] = useState(true);
  const [sortKey, setSortKey] = useState();
  const [sortableData, setSortableData] = useState([...crimeRatingData]);
  const buttonName = expanded ? t('common:actions.seeLess') : t('common:actions.seeMore');

  const handleSortClick = (key) => {
    const data = [...sortableData];
    let isReversed = sortToggle;
    sortKey === key && isReversed ? (isReversed = false) : (isReversed = true);
    setSortKey(key);
    setSortableData(sortDataByKey(data, key, isReversed));
    setSortToggle(isReversed);
  };

  const getSortIcon = (key) => {
    let imageIcon;
    if (sortKey === key) {
      if (sortToggle) {
        imageIcon = descendingSortIcon;
      } else {
        imageIcon = ascendingSortIcon;
      }
    } else {
      imageIcon = defaultSortIcon;
    }
    return imageIcon;
  };

  const renderHeaderCell = (item, isFirstRow) => {
    const isSortable = !!sortProperties[item];

    return (
      <Table.HeaderCell
        isFirstRow={isFirstRow}
        textAlign={item === 'state' ? 'left' : 'center'}
        key={item}
      >
        <Box display="flex" height={64}>
          <CellContentWrapper
            onClick={isSortable ? () => handleSortClick(sortProperties[item]) : undefined}
          >
            {labels[item]}
            {isSortable && (
              <ImageCore
                src={getSortIcon(sortProperties[item])}
                alt={`sort ${sortProperties[item]}`}
                ml={10}
              />
            )}
          </CellContentWrapper>
        </Box>
      </Table.HeaderCell>
    );
  };

  return (
    <React.Fragment>
      <Box overflowX="auto" pl={{ _: 24, lg: 40 }}>
        <TableWrapper expanded={expanded} ref={tableRef}>
          <Table>
            <Table.Head>
              <Table.Row>
                {Object.keys(labels).map((label) => renderHeaderCell(label, true))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {sortableData.map((rowData, i) => {
                const isOddRow = !(i % 2);

                return (
                  <Table.Row key={i} isOddRow={isOddRow}>
                    {Object.keys(labels).map((name) => (
                      <Table.Cell
                        textAlign={name === 'state' ? 'left' : 'center'}
                        isOddRow={isOddRow}
                        key={name}
                      >
                        <CellContentWrapper>
                          {name === 'state' && (
                            <ImageCore
                              src={rowData.image}
                              alt={rowData[name]}
                              height={24}
                              width={24}
                              mr={16}
                            />
                          )}

                          <Text.Body3
                            color={
                              name === 'score'
                                ? rowData.score >= 40
                                  ? 'danger'
                                  : rowData.score >= 25
                                  ? 'warning'
                                  : 'success'
                                : 'darkBlue'
                            }
                            fontWeight={name === 'state' || name === 'score' ? 700 : 500}
                          >
                            {name === 'losses'
                              ? '$' + rowData[name].toFixed(3).replace('.', ',')
                              : name === 'score'
                              ? rowData[name].toFixed(2)
                              : rowData[name]}
                          </Text.Body3>
                        </CellContentWrapper>
                      </Table.Cell>
                    ))}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </TableWrapper>
      </Box>
      <Box display="flex" justifyContent="center" pt={24}>
        <Button
          type="button"
          variant="text"
          size="large"
          width={280}
          onClick={() => {
            setExpanded(!expanded);
            expanded && window.scrollTo({ top: tableRef.current.offsetTop, behavior: 'smooth' });
          }}
          customEvent={{ eventName: GA4_EVENTS.crimeRatingTableExpandClick }}
        >
          <Box display="flex" alignItems="center">
            <Text.Body2Strong color="primary" mr={12}>
              {buttonName}
            </Text.Body2Strong>
            <ArrowIcon rotation={expanded ? -90 : 90} />
          </Box>
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default CrimeRatingTable;
