import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid } from '@core';
import { Animation, ContentBlock, Text } from '@components';
import { ArrowIcon } from '@components/arrow-link/arrow-icon';
import { GA4_EVENTS } from '@shared/constants/google';

const MethodologySection = ({ image, title, description, expandedDescription }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <Grid.Container data-section-id="methodology-section" px={{ _: 24, lg: 40 }}>
      <ContentBlock title={title} description={description} image={image}>
        <Animation.Height visible={expanded}>
          <Box pt={32}>
            <Text.Body1 whiteSpace="pre-line">{expandedDescription}</Text.Body1>
          </Box>
        </Animation.Height>
        <Box mt={8}>
          <Button
            type="button"
            variant="text"
            size="large"
            onClick={() => setExpanded(!expanded)}
            customEvent={{ eventName: GA4_EVENTS.methodologyDescriptionClick }}
          >
            <Box display="flex" alignItems="center">
              <Text.Body2Strong color="primary" mr={12}>
                {expanded ? t('common:actions.seeLess') : t('common:actions.seeMore')}
              </Text.Body2Strong>
              <ArrowIcon rotation={expanded ? -90 : 90} />
            </Box>
          </Button>
        </Box>
      </ContentBlock>
    </Grid.Container>
  );
};

export default MethodologySection;
