import React from 'react';

import { Box } from '@core';
import { SectionContainerSC as SectionContainer } from '@components';

import { CrimeRatingTable, MethodologySection } from './components';

const CrimeRatingTableSection = ({ title, description, methodologySection, tableData }) => (
  <SectionContainer
    title={title}
    description={description}
    wrapperProps={{ py: { _: 40, md: 80 } }}
  >
    <Box mb={80}>
      <MethodologySection {...methodologySection} />
    </Box>
    <CrimeRatingTable {...tableData} />
  </SectionContainer>
);

export default CrimeRatingTableSection;
